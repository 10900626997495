import { Flex, Icon, useColorModeValue, VStack } from '@chakra-ui/react';
import { AiOutlineGithub } from 'react-icons/ai';
import { FaLinkedin } from 'react-icons/fa';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Flex
      w={['90%', '60%']}
      mx="auto"
      mt="7rem"
      borderTop="1px"
      borderColor={useColorModeValue('gray.400', 'gray.500')}
      flexDir={['column-reverse', 'row']}
      color={useColorModeValue('gray.600', 'gray.300')}
    >
      <VStack
        spacing="2rem"
        alignItems="start"
        p="3rem"
        pt={['0', '3rem']}
        w={['100%', '50%']}
      >
        <a
          href="https://www.linkedin.com/in/pyon"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn <Icon ml="1px" mb="2px" as={FaLinkedin} />
        </a>
        <a href="https://github.com/danielpyon" target="_blank" rel="noreferrer">
          Github
          <Icon ml="3px" mb="2px" as={AiOutlineGithub} />{' '}
        </a>

        <Link to="mailto:pyondohyun@gmail.com">Email: pyondohyun@gmail.com</Link>
      </VStack>
    </Flex>
  );
};

export default Footer;
