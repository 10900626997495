import {
  Box,
  Button,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import ProjectsContainer from '../Projects/ProjectsContainer';
import WorkExperienceContainer from '../WorkExperience/WorkExperienceContainer';

const Dashboard = () => {
  return (
    <Box>
      <Heading mt="5rem" fontSize={['3xl', '4xl', '4xl', '5xl']}>
        Hi 👋
        <br />I'm Daniel Pyon!
      </Heading>
      <Text
        mt="1rem"
        fontSize="lg"
        mb="2rem"
        color={useColorModeValue('gray.600', 'gray.300')}
      >
        I'm an aspiring security researcher and software engineer from the Seattle area.
        <br />I like vulnerability research, reverse engineering, exploit development, and web development.
        <br />I study computer science at the University of Washington (check out <a rel="noreferrer" target="_blank" href="https://uwctf.cs.washington.edu/">Batman's Kitchen!</a>).
      </Text>
      <Heading mt="2rem" fontSize="4xl">
        Skills
      </Heading>
      <Box color={useColorModeValue('gray.700', 'gray.300')} ml="1rem">
        <Text fontSize="lg" fontWeight="bold" mt="1rem">
          Security
        </Text>
        <UnorderedList ml="2.5rem" mt="0.5rem">
          <ListItem>C/C++/Python</ListItem>
          <ListItem>x86, ARM</ListItem>
          <ListItem>Ghidra</ListItem>
          <ListItem>IDA</ListItem>
        </UnorderedList>

        <Text fontSize="lg" fontWeight="bold" mt="1rem">
          Web Development
        </Text>
        <UnorderedList ml="2.5rem" mt="0.5rem">
          <ListItem>Learning: Typescript, React</ListItem>
          <ListItem>Javascript, HTML, CSS</ListItem>
        </UnorderedList>
      </Box>
      <Heading mt="4rem" fontSize="4xl">
        Work Experience
      </Heading>
      <WorkExperienceContainer />

      <Heading mt="3rem" fontSize="4xl">
        Projects
      </Heading>
      <ProjectsContainer limit="3" />
      <RouterLink to="/projects">
        <Button
          color={useColorModeValue('black', 'white')}
          colorScheme="gray"
          ml="2rem"
          mt="1rem"
        >
          See More Projects 💡
        </Button>
      </RouterLink>
    </Box>
  );
};

export default Dashboard;
