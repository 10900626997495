export const projects = [
    {
      title: 'Binary Fuzzing Research',
      snippet: (
        <>
          Used <strong>AFL++</strong> with a custom harness to find bugs in a Markdown parser and gave a talk about it.
        </>
      ),
      projectUrl: '/fuzzing.pdf',
    },
    {
      title: 'Embedded Systems Fuzzing',
      snippet: (
        <>
          Implemented ideas in "Emulating Hypervisors: A Samsung RKP Case Study" to get 1) a blackbox fuzzer and 2) AFL++ working on QEMU.
        </>
      ),
    },
    {
      title: 'Hardware Implants: iDevice Driver Analysis',
      snippet: (
        <>
          Did taint analysis using <b>angr</b> to measure the attack surface of various IOKit drivers.
        </>
      ),
      projectUrl: '/idevice_hardware_implants.pdf'
    },
    {
      title: 'CTF and Wargame writeups',
      snippet: (
        <>
          I spend a lot of time playing capture the flag (CTF) and wargames in order to learn new security skills and stay up to date. Sometimes I post on my <a href="https://danielpyon.github.io/blog">blog</a> about problems I've solved, or new techniques I learn.
        </>
      ),
      projectUrl: 'https://github.com/danielpyon/ctf',
      detailPage: true,
    },
];
