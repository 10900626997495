const workExperiences = [
  {
    position: 'Incoming Vulnerability Researcher / Reverse Engineer Intern',
    duration: 'June 2024 - August 2024',
    companyName: 'Raytheon CODEX',
    description: (
      <>
        TODO!
      </>
    ),
  },
  {
    position: 'Security Engineer Intern',
    duration: 'June 2023 - August 2023',
    companyName: 'Security Innovation',
    description: (
      <>
        Found bugs in web & cloud apps. Did research on binary fuzzing. Attended DEFCON.
      </>
    ),
  },
];
export default workExperiences;
