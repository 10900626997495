export const projectsData = {
    'ctf-and-wargame-writeups': {
      name: 'CTF and Wargame writeups',
      date: 'January 8th, 2024',
      githubUrl: 'https://github.com/danielpyon/ctf',
      body: (
        <>
          I spend a lot of time playing capture the flag (CTF) and wargames in order to learn new security skills and stay up to date. Sometimes I post on my <a href="https://danielpyon.github.io/blog">blog</a> about problems I've solved, or new techniques I learn.
          <br/><br/>
          I started by doing pwn & rev, then got into web, and now I'm back to pwn & rev 🙂.
        </>
      ),
    },
};
  