import './App.css';

import { Box, useColorModeValue } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import Dashboard from './components/Dashboard/Dashboard';
import Error from './components/Error';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import ProjectPage from './components/Projects/ProjectPage';
import ScrollToTop from './components/ScrollToTop';
import Projects from './components/Projects/Projects';

function App() {
  return (
    <Router>
      <Helmet>
        <meta name="description" content="Daniel Pyon's portfolio website" />
        <meta name="keywords" content="Daniel Pyon, security engineer, software engineer" />
      </Helmet>
      <NavBar />
      <Box minH="100vh" pt="7rem" bgColor={useColorModeValue('white', 'black')}>
        <Box width={['85%', '70%', '60%']} marginX="auto" minH="40vh">
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Dashboard></Dashboard>} />
            <Route exact path="/projects" element={<Projects></Projects>} />
            <Route exact path="/projects/:project" element={<ProjectPage></ProjectPage>} />
            <Route exact path="*" element={<Error></Error>} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
